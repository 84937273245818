
import { Options, Vue } from 'vue-class-component'
import AdminMediaArchive from '@/components/MediaArchive/AdminMediaArchive/index.vue'

@Options({
  components: {
    AdminMediaArchive
  }
})

export default class MediaArchiveView extends Vue {}
