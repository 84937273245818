import { Vue, Options } from 'vue-class-component'
import MediasDataService from '@/services/MediasDataService'
import SysMediaArchive from '@/types/SysMediaArchive'
import Base, { mediaBaseURL } from '@/components/Utility/Base'
import { Watch } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'

@Options({
  props: {
    msg: String
  }
})
export default class AdminMediaArchive extends Vue {
  $Message: any
  msg!: string
  tabValue = 'Vis mediefiler'
  mediaFiles: SysMediaArchive[] = []
  mediaFile = {} as SysMediaArchive
  baseUrl = mediaBaseURL
  sortingValue = 'Upload'
  sortingOption: string[] = ['Upload', 'Ændret', 'Navn', 'Størrelse', 'Type']
  ascDesc = true
  currentSort = 'created_at:asc'
  editMediaFileDrawer = false
  extraParameter = ''
  filterValue = 'Alt'
  filterOptions: string[] = ['Alt', 'Billeder', 'Pdf', 'Klublogoer', 'Video', 'Lyd', 'Diverse']
  fullSizeImgModal = false
  urlModal = ''
  lastEditedMediaFile = 0
  playVideoModal = false
  deleteWarningModal = false
  viewURLModal = false
  uploadFiles: any[] = []
  uploadConfirmationText: string[] = []
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  currentPage = 1
  totalPages= 0
  totalNumberOfPages = 0
  searchTerm = ''
  searchInputValue = ''

  declare $refs: {
    videoPlayer: any
  }

  @Watch('pageSizeValueString')
  onPageSizeStringValueChange (newVal: string) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveMediaFiles()
    CommonFunctions.scrollToTop()
  }

  @Watch('filterValue')
  onFilerValueChange () : void {
    switch (this.filterValue) {
      case 'Billeder':
        this.extraParameter = 'mime_contains=image'
        break
      case 'Pdf':
        this.extraParameter = 'mime_contains=pdf'
        break
      case 'Klublogoer':
        this.extraParameter = 'mime_contains=image&name_contains=clublogo'
        break
      case 'Video':
        this.extraParameter = 'mime_contains=video'
        break
      case 'Lyd':
        this.extraParameter = 'mime_contains=audio'
        break
      case 'Diverse':
        this.extraParameter = 'mime_ncontains=image&mime_ncontains=pdf&mime_ncontains=video&mime_ncontains=audio'
        break
      default:
        this.extraParameter = ''
    }
    this.retrieveMediaFiles()
  }

  @Watch('uploadFiles')
  async onChangeBeginNewUpload () : Promise<void> {
    if (this.uploadFiles === undefined || this.uploadFiles === null || this.uploadFiles.length === 0) {
      return
    }

    for (const file of this.uploadFiles) {
      const fd: FormData = new FormData()
      fd.append('files', file)
      try {
        const fileResponse = await MediasDataService.upload(fd)

        // console.log(fileResponse.data)
        this.uploadConfirmationText.push(fileResponse.data[0].name + ' uploadet: ' + fileResponse.statusText)
      } catch (err) {
        console.log(err)
        this.uploadConfirmationText.push('Error: ' + err)
      }
    }
    this.uploadFiles = []
  }

  @Watch('fullSizeImgModal')
  onToggleValueChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.editMediaFile(this.lastEditedMediaFile)
    }
  }

  @Watch('playVideoModal')
  onVideoModalChange (toggleValue: boolean) : void {
    if (!toggleValue) {
      this.editMediaFile(this.lastEditedMediaFile)
    }
  }

  // @Watch('viewURLModal')
  // onURLModalChange (toggleValue: boolean) : void {
  //  if (!toggleValue) {
  //    this.editMediaFile(this.lastEditedMediaFile)
  //  }
  // }

  @Watch('sortingValue')
  onSortingValueChange () : void {
    switch (this.sortingValue) {
      case 'Upload':
        this.currentSort = 'created_at:'
        break
      case 'Ændret':
        this.currentSort = 'updated_at:'
        break
      case 'Navn':
        this.currentSort = 'name:'
        break
      case 'Størrelse':
        this.currentSort = 'size:'
        break
      case 'Type':
        this.currentSort = 'mime:'
    }
    this.currentSort += (this.ascDesc ? 'asc' : 'desc')
    // console.log(this.currentSort)
    this.retrieveMediaFiles()
  }

  @Watch('ascDesc')
  onAscDescChange (newVal: boolean) {
    const tempSort = this.currentSort.split(':')
    this.currentSort = tempSort[0] + ':' + (newVal ? 'asc' : 'desc')
    // console.log(this.currentSort)
    this.retrieveMediaFiles()
  }

  public turnToNewPage (pageChange: number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveMediaFiles()
  }

  public jumpToPage (pageNumber: number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveMediaFiles()
  }

  public findMediaFiles () : void {
    this.searchTerm = this.searchInputValue
    this.retrieveMediaFiles()
  }

  // public viewURL (url: string) : void {
  //   this.viewURLModal = true
  //   this.urlModal = mediaBaseURL + url
  //   this.editMediaFileDrawer = false
  // }

  public playVideo (url: string) : void {
    this.playVideoModal = true
    this.urlModal = mediaBaseURL + url
    this.editMediaFileDrawer = false
  }

  public seeFullSize (url: string) : void {
    this.fullSizeImgModal = true
    this.urlModal = mediaBaseURL + url
    this.editMediaFileDrawer = false
  }

  public resetSort () : void {
    this.searchInputValue = ''
    this.searchTerm = ''
    this.sortingValue = 'Upload'
    this.filterValue = 'Alt'
    this.ascDesc = true
    this.retrieveMediaFiles()
  }

  public async retrieveMediaFiles () : Promise<void> {
    // console.log('Counting entries')
    try {
      this.totalPages = (await MediasDataService.getCount(this.extraParameter)).data
    } catch (err) {
      console.log(err)
    }
    this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
    if (this.searchTerm.length > 0) {
      // console.log('Searching')
      this.currentPage = 1
      MediasDataService.findBySearchTerm(this.searchTerm, this.currentSort, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
        .then((response) => {
          this.mediaFiles = response.data
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      MediasDataService.getAll(this.currentSort, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, this.extraParameter)
        .then((response) => {
          this.mediaFiles = response.data
          // console.log(this.mediaFiles)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  public async editMediaFile (id: number) : Promise<void> {
    MediasDataService.get(id.toString())
      .then((response) => {
        this.mediaFile = response.data
        this.editMediaFileDrawer = true
        this.lastEditedMediaFile = Number(this.mediaFile.id)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public danishDate (dateString: string) : string {
    return CommonFunctions.toDanishDateString(dateString) + ' kl: ' + new Date(dateString).toLocaleTimeString()
  }

  public async updateMediaFile (mediaId: number) : Promise<void> {
    if (this.mediaFile.name === '') {
      this.$Message.danger({ text: 'Fejl: Filen skal have et navn' })
    }

    const updateFile = {
      name: this.mediaFile.name,
      alternativeText: this.mediaFile.alternativeText,
      caption: this.mediaFile.caption
    }
    const fd: FormData = new FormData()
    fd.append('fileInfo', JSON.stringify(updateFile))

    MediasDataService.update(mediaId.toString(), fd)
      .then((response) => {
        console.log(response.data)
        this.editMediaFileDrawer = false
        this.$Message.success({ text: 'Filen er blevet opdateret' })
        this.retrieveMediaFiles()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  public deleteWarning () : void {
    this.deleteWarningModal = true
    this.editMediaFileDrawer = false
  }

  public cancelDelete () : void {
    this.deleteWarningModal = false
    this.$Message.warning({ text: 'Sletning annulleret' })
    this.editMediaFile(this.lastEditedMediaFile)
  }

  public async deleteFile () : Promise<void> {
    MediasDataService.delete(this.lastEditedMediaFile.toString())
      .then((response) => {
        console.log(response.data)
        this.deleteWarningModal = false
        this.$Message.success({ text: 'Filen er blevet slettet' })
        this.lastEditedMediaFile = 0
        this.retrieveMediaFiles()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async mounted () : Promise<void> {
    this.retrieveMediaFiles()
  }
}
